module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["nl","en"],"defaultLanguage":"nl","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Een Facebookgroep website","short_name":"Fbgroep site","description":"De website van de Facebook groep Coaches grow your business online","lang":"nl","start_url":"/","background_color":"#ffffff","theme_color":"#004aad","display":"standalone","icon":"src/images/flavicon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"localize":[{"start_url":"/en/","lang":"en","name":"A Facebook group website","short_name":"Fbgroup site","description":"The website of the Dutch Facebook group Coachesgrow your business online"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6594e580df0382e23873f66a6e4a0d2d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
